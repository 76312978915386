/**
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 */
import { Campaign, PointOfSale } from '../model/models';
import { LogItem, LogLevel } from '../services/LogService';

export interface GlobalState {
  showAnimSplash: boolean;
  showDebug: boolean;
  setErrorMode: boolean;
  isLoggerReady: boolean;
  isDesktop : boolean;
  locale: string;
  logLevel: LogLevel;
  logs: LogItem[];
  pointOfSale: PointOfSale | undefined;
  campaigns: Campaign[];
  isLoading: boolean,
}

/** Actions */
export enum ActionType {
  ShowAnimSplash,
  ShowDebug,
  SetErrorMode,
  SetLogLevel,
  SetIsDesktop,
  AddLog,
  SetPointOfSale,
  SetCampaigns,
  SetIsLoading
}

export interface ShowAnimSplash {
  type: ActionType.ShowAnimSplash;
  payload: boolean;
}

export interface ShowDebug {
  type: ActionType.ShowDebug;
  payload: boolean;
}

export interface SetErrorMode {
  type: ActionType.SetErrorMode;
  payload: boolean;
}

export interface SetLogLevel {
  type: ActionType.SetLogLevel;
  payload: LogLevel;
}

export interface SetIsDesktop {
  type: ActionType.SetIsDesktop;
  payload: boolean;
}

export interface AddLog {
  type: ActionType.AddLog;
  payload: LogItem;
}

export interface SetPointOfSale {
  type: ActionType.SetPointOfSale;
  payload: PointOfSale;
}

export interface SetCampaigns {
  type: ActionType.SetCampaigns;
  payload: Campaign[];
}

export interface SetIsLoading {
  type: ActionType.SetIsLoading;
  payload: boolean;
}

export type Actions =
  | ShowAnimSplash
  | ShowDebug
  | SetErrorMode
  | SetLogLevel
  | SetIsDesktop
  | AddLog
  | SetPointOfSale
  | SetCampaigns
  | SetIsLoading


const getInitialLogLevel = () => {
  const APP_ENV_LOG_LEVEL:any = {
    develop: 1, // LogLevel.info
    preprod: 2, // LogLevel.warn
    production: 2, // LogLevel.warn
    default: 2, // LogLevel.warn
  };
  // const env:string = process?.env?.REACT_APP_ENV || 'default';
  const env:string = 'develop';
  return APP_ENV_LOG_LEVEL[env];
};

/* Initial State */
export const INITIAL_GLOBAL_STATE: GlobalState = {
  showAnimSplash: true,
  showDebug: false,
  setErrorMode: false,
  locale: 'en_US',
  isLoggerReady: false,
  isDesktop: false,
  logLevel: getInitialLogLevel(),
  logs: [],
  pointOfSale: undefined,
  campaigns: [],
  isLoading: true,
};

/* reducer */
export function globalStateReducer(
  state: GlobalState,
  action: Actions
): GlobalState {
  switch (action.type) {
    case ActionType.ShowAnimSplash:
      return { ...state, showAnimSplash: action.payload };

    case ActionType.ShowDebug:
      return { ...state, showDebug: action.payload };

    case ActionType.SetErrorMode:
      return { ...state, setErrorMode: action.payload };

    case ActionType.AddLog:
      return { ...state, logs: [...state.logs, action.payload] };

    case ActionType.SetIsDesktop:
      return { ...state, isDesktop: action.payload };
  
    case ActionType.SetLogLevel:
      return { ...state, logLevel: action.payload, isLoggerReady: true };

    case ActionType.SetPointOfSale:
      return { ...state, pointOfSale: action.payload };
    
    case ActionType.SetCampaigns:
      return { ...state, campaigns: action.payload };
    
    case ActionType.SetIsLoading:
      return { ...state, isLoading: action.payload };
    
  }
}
