
import { Menu, Transition } from '@headlessui/react';
import { useContext, Fragment, useState } from 'react';
import { AppContext } from '../../state/AppContextProvider';

interface MenuProps {}

export const MenuDropdown: React.FC<MenuProps> = () => {
  const { state } = useContext(AppContext);
  const pointOfSale = state.pointOfSale;
  const mainColor = {
      color: pointOfSale?.sites[0]?.siteColorMain
  }

  const [menuOpen,setMenuOpen] = useState(false)

  return (
    pointOfSale?.sites[0]?.menus?.dropdown && pointOfSale?.sites[0]?.menus?.dropdown.length > 1 ? (
      <Menu as="div" className="m-0">
          <>
            <Menu.Button className="inline-flex items-center gap-4 text-left" onClick={() => setMenuOpen(!menuOpen)}>
              <p className="font-semibold text-[#3D3E43]">{pointOfSale?.name}</p>
              <svg className={`block duration-500 ${menuOpen ? 'rotate-180' : ''}`} style={mainColor} width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0.923828L8 7.07617L15 0.923828" stroke="#404040" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="z-[9999] absolute translate-x-0 top-[72px] left-0 md:left-28 w-max flex flex-col gap-4 p-5 rounded-b-2xl shadow-lg bg-white">
                {pointOfSale?.sites[0]?.menus?.dropdown.map((data, index) =>
                  <Menu.Item key={data.siteMenuLinkId}>
                    {({ active }) => (
                        <a
                          href={data.siteMenuLinkValue}
                          className={`bold-on-hover ${index < 1 ? '':' pt-4 border-t border-[#EDEEF2]'}`}
                        >
                          <p>{data.siteMenuLinkTitle}</p>
                        </a>
                    )}
                  </Menu.Item>
                  )}
            </Menu.Items>
          </Transition>
        </>
      </Menu>
    ) : (
    <div className="inline-block text-left">
      <span className="font-semibold text-[14px] md:text-[18px] leading-normal" style={mainColor}>{pointOfSale?.name}</span>
    </div>
  )

  );
}