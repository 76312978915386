import Skeleton from "./Skeleton";

const SkeletonHeader  : React.FC<{}> = () => {
  return (
    <>
        <div className="flex gap-4 items-center space-between">
            <Skeleton className="w-10 h-10" />
            <Skeleton className="w-52 h-6" />
        </div>
        <div className={`flex`}>
          <div className="hidden md:flex items-center gap-x-8">
          <Skeleton className="h-6 w-28" />
          <Skeleton className="h-6 w-14" />
          <Skeleton className="h-12 w-20" />
          </div>
          <div className='flex md:hidden'>
              <Skeleton className="h-10 w-10" />
          </div>
        </div>
    </>
  );
};
export default SkeletonHeader;
