import imagedefault from '../../img/image-default.jpg';
import { formatText } from '../global/Card';


interface CardProps {
    isCampaign?: boolean;
    id: string;
    mainColor?: string;
    image: string;
    title: string;
    description: string;
    campaignUrl?: string;
    partnerLogo?: string;
}


const CampaignLinkCard : React.FC<CardProps> = ({
    isCampaign = true,
    id,
    mainColor,
    image,
    title,
    description,
    campaignUrl,
    partnerLogo,
}) => {
    

    return(
        <div className="w-full md:w-1/2 flex flex-col p-3">
            <div className="card flex flex-col md:flex-row flex-1 bg-white rounded-xl overflow-hidden">
                {/* Image Part */}
                <div className="h-48 md:h-60 md:w-1/3 overflow-hidden">
                    <img className={`card-image object-cover h-full w-full transition-all duration-500`} src={image ?? imagedefault} alt="Partenaire" />
                </div>
                {/* Description Part */}
                <div className="flex flex-col flex-1 justify-between gap-5 p-6">
                    <div className="flex flex-col">
                        <h4 className="line-clamp-1 mb-2">{title}</h4>
                        {description && <p className="description-short line-clamp-4" dangerouslySetInnerHTML={{__html: formatText(description)}}/>}
                    </div>
                    <div className="flex flex-col gap-2">
                        {campaignUrl &&
                        <div className='flex gap-3 items-center cursor-pointer' onClick={() => window.open(campaignUrl, "_blank")}>
                            <span className='text-base font-bold' style={{color:mainColor}}>Je tente ma chance</span> 
                            <svg className='h-5 -rotate-90' viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.21772 1.32007L7.21772 17.3201M7.21772 17.3201L0.995497 11.0978M7.21772 17.3201L13.4399 11.0978" stroke={mainColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>             
                        </div>
                        }
                    </div>
                </div>         
            </div>
        </div> 

    )
}

export default CampaignLinkCard;