import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../state/AppContextProvider';
import { MenuDropdown } from '../nav/MenuDropdown';
import { MenuContact } from '../nav/MenuContact';
import { stack as MenuStack } from 'react-burger-menu';
import cross from '../../img/menu-cross.svg';
import burger from '../../img/menu-burger.svg';
import { ActionType } from '../../state/GlobalState';
import { Link } from 'react-router-dom';
import SkeletonHeader from '../shared/Skeleton/SkeletonHeader';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { state, dispatch } = useContext(AppContext);
  const pointOfSale = state.pointOfSale;
  
  const [isOpen, setOpen] = useState(false);
  const isMenuOpen = function(state : {isOpen: boolean}) {
    setOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = function () {
      if (window.innerWidth >= 768 && !state.isDesktop) {
        dispatch({ type: ActionType.SetIsDesktop, payload: true });
      } else if (window.innerWidth < 768 && state.isDesktop) {
        dispatch({ type: ActionType.SetIsDesktop, payload: false });
      }
    }
  
    window.addEventListener('resize', handleResize)
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
      <>
        <Helmet>
          <title>{`${((pointOfSale?.name)?pointOfSale?.name:"")} | ${pointOfSale?.sites[0]?.siteTitle.replace(/<[^>]+>/g, '')}`}</title>
          {
            pointOfSale?.sites[0].siteLogo  && <link rel="icon" type="image/png" href={pointOfSale?.sites[0].siteLogo} sizes="16x16" />
          }
        </Helmet>
        <header className="py-4 px-6 md:py-3 md:px-14 2xl:px-16 flex items-center justify-between gap-5">
          {!state.isLoading ? (
            <>
              <div className="flex gap-4 items-center space-between">
                <Link to={`/`}>
                  <img className="w-10 h-10 object-cover" src={pointOfSale?.sites[0]?.siteLogo} alt="Logo" />
                </Link>
                <MenuDropdown />
              </div>
              <div className={`menu flex ${!isOpen ? 'is-open':''}`}>
                <div className="hidden md:flex items-center gap-x-8">
                  {pointOfSale?.sites[0]?.menus?.link.map((data, index) => <li  className="links list-none" key={`desktop-${data.siteMenuLinkId}`}><a className={`whitespace-nowraptext-[${pointOfSale?.sites[0]?.siteColorMain}] font-semibold bold-on-hover`} target="_blank" rel="noreferrer" href={data.siteMenuLinkValue}>{data.siteMenuLinkTitle}</a></li>)}
                  <MenuContact />
                </div>
                <div className='flex md:hidden'>
                  <MenuStack className='!p-8' noTransition onStateChange={ isMenuOpen } width={ '100%' } customBurgerIcon={ <img alt="MenuBurger" src={burger} /> } customCrossIcon={ <img alt="MenuCross" src={cross} /> }>
                    <div className='!flex flex-col gap-8 !p-0'>
                    {pointOfSale?.sites[0]?.menus?.link.map((data, index) => <li  className="list-none !p-0" key={`mobile-${data.siteMenuLinkId}`}><a className={`font-semibold hover:font-bold`} target="_blank" rel="noreferrer" href={data.siteMenuLinkValue}>{data.siteMenuLinkTitle}</a></li>)}
                    <MenuContact />
                    </div>
                  </MenuStack>
                </div>
              </div>
            </>
            ):(
            <SkeletonHeader/>
            )}
        </header>
      </>
  );
};

export default Header;