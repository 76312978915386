import { useContext } from 'react';
import { AppContext } from '../../state/AppContextProvider';
import Card from '../global/Card';
import { cardWithPropsPartner } from '../partner/PartnerCards';

interface PartnerCardsProps {}

const CampaignCards: React.FC<PartnerCardsProps> = () => {
    const { state } = useContext(AppContext);
    return (
        <div className="flex flex-wrap">
           {state.campaigns.length > 0 && state.campaigns.map((campaign, index) =>
                cardWithProps(index,campaign,state.pointOfSale?.sites[0]?.siteColorMain)
            )}
            {state.pointOfSale?.sites[0]?.partners?.filter((partner) => partner.section === 'primary').map((partner, index) =>
                cardWithPropsPartner(index,partner,state.pointOfSale?.sites[0]?.siteColorMain)
            )}
        </div>
    );
};

export default CampaignCards;


export const cardWithProps =  (key:any,campaign :any,color?:string) => {
    const {campaignId,campaignName, campaignDescription,campaignShortUrl ,campaignImage } = campaign;
    return (
        <Card   
            key={key}
            id={campaignId}
            mainColor={color}
            image={campaignImage}
            title={campaignName}
            description={campaignDescription}
            campaignUrl={campaignShortUrl}
        />
    )

}