import React, { createContext, useReducer } from "react";
import { Actions, GlobalState, INITIAL_GLOBAL_STATE, globalStateReducer } from "./GlobalState";

/* App Context */
export const AppContext = createContext<{
    state: GlobalState;
    dispatch: React.Dispatch<Actions>;
}>({
    state: INITIAL_GLOBAL_STATE,
    dispatch: () => undefined,
});

/** 
 * AppContextProvider
 */
export const AppContextProvider = (props: any) => {
    const fullInitialState = {
        ...INITIAL_GLOBAL_STATE,
    };

    const [state, dispatch] = useReducer(globalStateReducer, fullInitialState);

    const value = { state, dispatch };

    return (
        <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
    );
}