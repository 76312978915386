
import { Menu, Transition } from '@headlessui/react';
import { useContext, Fragment } from 'react';
import { AppContext } from '../../state/AppContextProvider';

interface MenuProps {}

export const MenuContact: React.FC<MenuProps> = () => {
    const { state } = useContext(AppContext);
    const pointOfSale = state.pointOfSale;

    const isEmail = (email:string) => {
      const regex = /^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})$/;
      return email.match(regex);
    }

    return (
      pointOfSale?.sites[0]?.menus?.contact && pointOfSale?.sites[0]?.menus?.contact.length > 0 ? (
        <Menu as="div" className='flex flex-col !p-0 gap-5'>
          {({ open }) => (
            <>
              <Menu.Button style={{backgroundColor: pointOfSale?.sites[0]?.siteColorMain}} className="w-full hidden md:flex items-center justify-between font-bold max-h-max px-6 py-3 text-white rounded-xl hover:opacity-90">
                  Contact
                </Menu.Button>
                <Menu.Button className={`w-full flex md:hidden pr-1 items-center justify-between font-bold`}>
                  <p className='text-base font-semibold'>Contact</p>
                  {open ? (
                    <svg className="rotate-90" width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 15L8 8L1 1" stroke={pointOfSale?.sites[0]?.siteColorMain} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  ):(
                    <svg width="9" height="16" viewBox="0 0 9 16" fill= "none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 15L8 8L1 1" stroke={pointOfSale?.sites[0]?.siteColorMain} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  )}
                </Menu.Button>
              
    
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="flex flex-col gap-4 !p-0 md:z-50 md:absolute md:top-[72px] md:right-14 md:!p-5 md:rounded-b-2xl md:shadow-lg md:bg-white">
                  {pointOfSale?.sites[0]?.menus?.contact.map((data, index) => // <option value={data.siteMenuLinkId}>{data.siteMenuLinkTitle}</option>
                  <div className='!p-0 flex flex-col gap-4' key={data.siteMenuLinkId}>
                      <a href={`${isEmail(data.siteMenuLinkValue) ? `mailto:${data.siteMenuLinkValue}` : `${data.siteMenuLinkValue}`} `} className={`p-0 bold-on-hover text-sm ${index < 1 ? '':' md:pt-4 md:border-t border-[#EDEEF2]'}`} target="_blank" rel="noopener noreferrer">
                        <p>{data.siteMenuLinkTitle}</p>
                      </a>
                  </div>
                  )}
              </div>
            </Transition>
          </>
        )}
        </Menu>
      ) : (
        <></>
      )
  );
}