import { Link } from "react-router-dom";
import imagedefault from '../../img/image-default.jpg';
import BasicButton from "../shared/BasicButton";


interface CardProps {
    isCampaign?: boolean;
    id: string;
    mainColor?: string;
    image: string;
    title: string;
    description: string;
    campaignUrl?: string;
    numberOfCampaigns?: number;
    partnerLogo?: string;
}


const Card : React.FC<CardProps> = ({
    isCampaign = true,
    id,
    mainColor,
    image,
    title,
    description,
    campaignUrl,
    numberOfCampaigns = 0 ,
    partnerLogo,
}) => {
    


    return(
        <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col p-3">
            <div className="card flex flex-col flex-1 bg-white rounded-xl overflow-hidden">
                {/* Image Part */}
                <div className="h-48 md:h-60 overflow-hidden">
                    <img className={`card-image object-cover h-full w-full transition-all duration-500`} src={image ?? imagedefault} alt="Partenaire" />
                </div>
                {/* Description Part */}
                <div className="flex flex-col flex-1 justify-between gap-5 p-6">
                    <div className="flex flex-col">
                        {partnerLogo && <div className="relative">
                            <img className="absolute -top-16 md:-top-[4.5rem] w-12 h-12 md:w-14 md:h-14 rounded-full object-cover bg-white" alt="Logo partenaire" src={partnerLogo} />            
                        </div>}
                        <h4 className="line-clamp-1 mb-2">{title}</h4>
                        {description && <p className="description-short line-clamp-4" dangerouslySetInnerHTML={{__html: formatText(description)}}/>}
                    </div>
                    <div className="flex flex-col gap-2">
                        {campaignUrl &&
                        <div onClick={() => window.open(campaignUrl, "_blank")}>
                            <BasicButton className={`w-full text-white`} style={{backgroundColor: mainColor}} text='Je participe'/>              
                        </div>
                        }
                        {((description && description.length > 128) || numberOfCampaigns > 1 ) && 
                            <Link to={`/${isCampaign ? 'campaign' :'partners'}/${id}`}>
                                <BasicButton className={`w-full`} text='En savoir plus'/>
                            </Link>
                        }
                    </div>
                </div>         
            </div>
        </div> 

    )
}

export default Card;


export const formatText = (description : string) => {
    return description.replaceAll('<br>', '');
}