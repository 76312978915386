import { Link } from 'react-router-dom';

interface PageFooterProps {
  color? : string;
  text? :string;
}

const PageFooter: React.FC<PageFooterProps> = ({text,color}) => {
  window.scrollTo(0,0);

  return (
    <div className="flex flex-col gap-8 bg-gray-100 p-8">
      <Link className="flex gap-3 items-center" to="/">
        <svg className="rotate-180" width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="25" cy="25" r="25" fill={color}/>
          <path d="M16 25H34M34 25L27 32M34 25L27 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <p>Retour</p>
      </Link>
      {text && 
        <div className='wysiwyg' dangerouslySetInnerHTML={{__html: text}}></div>  
      }
    </div>
  );
};

export default PageFooter;