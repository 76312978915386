import { CSSProperties } from "react";

interface BasicButtonProps {
    className?: string;
    style?:CSSProperties;
    text:string;
    onClick?:any;
}


const BasicButton : React.FC<BasicButtonProps> = ({className,text,onClick,style}) => {
    return (
        <button className={`${className} bg-gray-100 p-3 rounded-xl text-sm font-semibold`} style={style} onClick={onClick}>{text}</button>

    )

}

export default BasicButton;