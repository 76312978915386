import { useContext } from 'react';
import { PointOfSale } from '../../model/models';
import { AppContext } from '../../state/AppContextProvider';
import PartnerCards from '../partner/PartnerCards';
import CampaignCards from '../campaign/CampaignCards';
import SkeletonCards from '../shared/Skeleton/SkeletonCards';
import SkeletonTitle from '../shared/Skeleton/SkeletonTitle';

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const { state } = useContext(AppContext);
  const pointOfSale : PointOfSale | undefined = state.pointOfSale;

  function createTitleTag(text? : string) {
    if(text) {
      return (<h2 className="text-center" dangerouslySetInnerHTML={{__html: text}}></h2>);
    }
  }
  function createSubTitleTag(text? : string) {
    if(text) {
      return (<h3 className="text-center" dangerouslySetInnerHTML={{__html: text}}></h3>);
    }
  }

  function createDescriptionTag(text?:string) {
    if(text) {
      const parser = new DOMParser();
      const description = parser.parseFromString(text, "text/html")
      return (<h5 className="text-center">{description.body.textContent}</h5>);
    }
  }

  return (
    <div className="bg-gray-100 flex flex-col gap-14 md:gap-24 p-5 md:py-16 md:px-14 2xl:px-16">
      {
        state.isLoading ? (
        <>
          <section>
            <div className='flex flex-col gap-10 md:gap-14'>
              <SkeletonTitle/>
              <SkeletonCards/>
            </div>
          </section>
          <section>
            <div className='flex flex-col gap-10 md:gap-14'>
              <SkeletonTitle/>
              <SkeletonCards/>
            </div>
          </section>
        </>
        ) : (
          <>
          {
            (state.campaigns.length > 0 || (pointOfSale && pointOfSale.sites[0]?.partners?.filter((partner) => partner.section === 'primary').length > 0)) &&
            <section>
              <div className='flex flex-col gap-10 md:gap-14'>
                <div className='grid gap-4'>
                  {createTitleTag(pointOfSale?.sites[0]?.siteTitle)}
                  {createDescriptionTag(pointOfSale?.sites[0]?.siteDescription)}
                </div>
                <CampaignCards/>
              </div>
            </section>
          }
          {(pointOfSale && pointOfSale.sites[0]?.partners?.filter((partner) => partner.section === 'secondary').length > 0) &&
            <section>
              <div className='flex flex-col gap-10 md:gap-14 justify-items-center'>
                  <div className='grid gap-4'>
                    {createSubTitleTag(pointOfSale?.sites[0]?.siteTitleSecondary)}
                    {createDescriptionTag(pointOfSale?.sites[0]?.siteDescriptionSecondary)}
                  </div>
                <PartnerCards />
              </div>
            </section>
            }
          </>
        )
      }  
    </div>
  );
};

export default Home;