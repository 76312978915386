import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Campaign, PointOfSale } from '../../model/models';
import { AppContext } from '../../state/AppContextProvider';
import imagedefault from '../../img/image-default.jpg';
import BasicButton from '../shared/BasicButton';


const CampaignPage : React.FC<{}> = () => {
    window.scrollTo(0,0);

    const { state } = useContext(AppContext);

    const pointOfSale : PointOfSale | undefined = state.pointOfSale;
    const campaignId :  string | undefined = useParams().campaignId;
    const [campaign,setCampaign] = useState<Partial<Campaign> | undefined>(undefined)
    
    useEffect(() => {
        if (state && campaignId) {
            setCampaign(state.campaigns.find(campaign => campaign.campaignId === parseInt(campaignId)));
        }
    },[state,campaignId]);


    const image = campaign?.campaignImage ?? imagedefault;

    return (
        <>
            <div className="bg-gray-100 md:py-16 md:px-14 2xl:px-16 !pb-0">
                {/* Desktop */}
                <div className='hidden md:block'>
                    <div className="flex flex-row bg-white rounded-3xl min-h-[30vh]">
                        {/* Titre Description */}
                        <div className="w-1/2 p-16 pt-8 flex flex-col gap-6">
                            <Link className='w-full h-20 inline-flex gap-3 items-center' to={'/'}>
                                <svg className="rotate-180" width="32" height="32" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="25" cy="25" r="25" fill={pointOfSale?.sites[0]?.siteColorMain}/>
                                    <path d="M16 25H34M34 25L27 32M34 25L27 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <p>Retour</p>
                            </Link>
                            <div className='flex flex-col gap-10'>
                                <h2>{campaign?.campaignName}</h2>         
                                {campaign?.campaignShortUrl &&
                                <div onClick={() => campaign?.campaignShortUrl &&  window.open(campaign.campaignShortUrl, "_blank")}>
                                    <BasicButton className={`block text-white px-8 py-4`} style={{backgroundColor: pointOfSale?.sites[0].siteColorMain}} text='Je participe'/>              
                                </div>
                                }
                            </div>
                        </div>
                        {/* Post image */}
                        <div className={`w-1/2 rounded-3xl post-image`} style={{backgroundImage: `url(${image})`}}/> 

                    </div>
                    <div className="description p-16 whitespace-break-spaces">
                        {campaign?.campaignDescription}
                    </div>
                </div>
                {/* Mobile */}
                <div className='relative md:hidden'>
                    {/* Post image */}
                    <div className="h-[50vh]">
                        <img className="h-full w-full object-cover md:rounded-3xl" src={campaign?.campaignImage ?? imagedefault} alt="Campaign" />
                    </div>
                    {/* Titre Description */}
                    <div className="inline-block bg-gray-100 rounded-2xl mt-[-8rem]">
                        <Link className='inline-flex gap-3 items-center' to={'/'}>
                            <svg className="absolute left-8 top-8 rotate-180" width="42" height="42" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="25" cy="25" r="25" fill={pointOfSale?.sites[0]?.siteColorMain}/>
                                <path d="M16 25H34M34 25L27 32M34 25L27 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </Link>
                        <div className='flex flex-col gap-8 items-center p-5 pb-10'>
                            <h2 className='text-center'>{campaign?.campaignName}</h2>         
                            {campaign?.campaignShortUrl &&
                            <div onClick={() => campaign?.campaignShortUrl &&  window.open(campaign.campaignShortUrl, "_blank")}>
                                <BasicButton className={`text-white px-8 py-4`} style={{backgroundColor: pointOfSale?.sites[0].siteColorMain}} text='Je participe'/>              
                            </div>
                            }
                            <div className="description p-0 whitespace-break-spaces">
                                {campaign?.campaignDescription}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CampaignPage;