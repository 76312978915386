import { Link } from 'react-router-dom';


const Footer: React.FC<{}> = () => {
  return (
      <footer className="py-6 flex gap-4 items-center justify-center">
          <Link key="mentions" to={`/mentions-legales`}>
            <p className='text-sm font-semibold'>Mentions</p>
          </Link>
          <a  key="cookies" href="javascript:tarteaucitron.userInterface.openPanel();">
              <p className='text-sm font-semibold'>Cookies</p>
          </a>
          <Link key="pdc" to={`/politique-de-confidentialite`}>
            <p className='text-sm font-semibold'>CGU</p>
          </Link>
      </footer>
  );
};

export default Footer;