import { useContext, useEffect } from "react";
import { ActionType } from "../../state/GlobalState";
import { AppContext } from "../../state/AppContextProvider";
import { getPointOfSale, getActiveCampaigns } from "../../services/api";
import { PointOfSale } from "../../model/models";

export const LoadPointOfSale: React.FC = () => {
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        !state.pointOfSale && getPointOfSale().then((res : boolean | PointOfSale) => {
            if (typeof res == "boolean") {
                dispatch({ type: ActionType.SetErrorMode, payload: true });
            }   else {
                dispatch({ type: ActionType.SetPointOfSale, payload: res });
            }
        })
        .catch((err) => {
            dispatch({ type: ActionType.SetErrorMode, payload: true });
          });
        if (state.pointOfSale) {
            getActiveCampaigns(state.pointOfSale.pointOfSaleId).then((res) => {
                dispatch({ type: ActionType.SetCampaigns, payload: res });
            })
            dispatch({ type: ActionType.SetIsLoading, payload: false });
        }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state.pointOfSale]);

    return (<></>);
}