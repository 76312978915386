import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Partner, PointOfSale } from '../../model/models';
import { AppContext } from '../../state/AppContextProvider';
import imagedefault from '../../img/image-default.jpg';
import CampaignLinkCard from '../campaign/CampaignLinkCard';

interface PartnersProp {}

const PartnersPage : React.FC<PartnersProp> = () => {
    window.scrollTo(0,0);

    const { state } = useContext(AppContext);
    const pointOfSale : PointOfSale | undefined = state.pointOfSale;
    const partnerId :  string | undefined = useParams().partnerId;
    let partner : undefined | Partner = undefined;
    if (partnerId) {
        partner = pointOfSale?.sites[0]?.partners.find(the_partner => the_partner.partnerId === parseInt(partnerId));
    }

    const image = partner?.partnerImage ?? imagedefault;
    return (
            <div className="bg-gray-100 md:py-16 md:px-14 2xl:px-16 !pb-0">
                {/* Desktop */}
                <div className='hidden md:block'>
                    <div className="flex flex-row bg-white rounded-3xl min-h-[30vh]">
                        {/* Titre Description */}
                        <div className="w-1/2 p-16 pt-8 flex flex-col gap-6">
                            <div className='w-full h-20 flex justify-between'>
                                <Link className='inline-flex gap-3 items-center' to={'/'}>
                                    <svg className="w-8 h-8 rotate-180" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="25" cy="25" r="25" fill={pointOfSale?.sites[0]?.siteColorMain}/>
                                        <path d="M16 25H34M34 25L27 32M34 25L27 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <p>Retour</p>
                                </Link>
                                {partner?.partnerLogo && (<img className="h-full w-20 rounded-full object-cover" src={partner?.partnerLogo} alt="Logo partenaire" />)}      
                            </div>
                            <div className='flex flex-col gap-10'>
                                <h2>{partner?.partnerName}</h2>
                                {partner?.campaigns && partner?.campaigns?.length > 0 ? (
                                    <a style={{backgroundColor:state.pointOfSale?.sites[0].siteColorMain}} className="w-fit smooth-scroll-element flex gap-2 items-center px-8 py-4 cursor-pointer rounded-xl" href="#games">
                                        <p className='text-white'>Accéder aux jeux</p>
                                        <svg className='h-4' viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.21772 1.32007L7.21772 17.3201M7.21772 17.3201L0.995497 11.0978M7.21772 17.3201L13.4399 11.0978" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </a>
                                ):""}
                            </div>
                        </div>
                        {/* Post image */}
                        <div className={`w-1/2 rounded-3xl post-image`} style={{backgroundImage: `url(${image})`}}/> 
                    </div>
                   { partner?.partnerDescription && <div className="description p-16"  dangerouslySetInnerHTML={{__html: partner?.partnerDescription}}/>}
                </div>
                {/* Mobile */}
                <div className='relative md:hidden'>
                    {/* Post image */}
                    <div className="h-[50vh]">
                        <img className="h-full w-full object-cover md:rounded-3xl" src={partner?.partnerImage ?? imagedefault} alt="partner" />
                    </div>
                    {/* Titre Description */}
                    <div className="w-full inline-block bg-gray-100 rounded-2xl mt-[-8rem]">
                        <Link className='inline-flex gap-3 items-center' to={'/'}>
                            <svg className="absolute left-8 top-8 rotate-180" width="42" height="42" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="25" cy="25" r="25" fill={pointOfSale?.sites[0]?.siteColorMain}/>
                                <path d="M16 25H34M34 25L27 32M34 25L27 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </Link>
                        <div className='flex flex-col gap-8 items-center p-5 pb-10'>
                            {partner?.partnerLogo && (<img className="w-24 h-24 rounded-full object-cover" src={partner?.partnerLogo} alt="Logo partenaire" />)}      
                            <h2 className='text-center'>{partner?.partnerName}</h2>
                            { partner?.partnerDescription && <div className="description p-0" dangerouslySetInnerHTML={{__html: partner?.partnerDescription}}/>}
                        </div>
                    </div>
                </div>
                <div id="games" className="flex flex-wrap">
                {partner?.campaigns?.map((campaign, index) =>
                    <CampaignLinkCard id={campaign.campaignId.toString()} image={campaign.campaignImage} title={campaign.campaignName} description={campaign.campaignDescription} campaignUrl={campaign.campaignShortUrl} mainColor={state.pointOfSale?.sites[0].siteColorMain}/>
                )}
            </div>
        </div>
    );
};

export default PartnersPage;