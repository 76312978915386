import Skeleton from "./Skeleton";

const SkeletonTitle : React.FC<{}> = () => {
  return (
      <div className="flex flex-col gap-4 items-center">
        <Skeleton className="h-20 w-2/3" />
        <Skeleton className="h-7 w-1/3" />
    </div>         
  );
};
export default SkeletonTitle;

