import Skeleton from "./Skeleton";

const SkeletonCard  : React.FC<{}> = () => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col p-3">
      <div className="card flex flex-col flex-1 bg-white rounded-xl overflow-hidden">
        <div className="h-48 md:h-60 overflow-hidden">
          <Skeleton className="h-full w-full" />
        </div>
        <div className="flex flex-col flex-1 justify-between gap-5 p-6">
          <div className="flex flex-col">
            <div className="relative">
              <Skeleton className="absolute -top-16 md:-top-[4.5rem] w-12 h-12 md:w-14 md:h-14 rounded-full" />
            </div>
            <Skeleton className="h-6 mb-2 w-3/4" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-5/6" />
              <Skeleton className="h-4 w-2/3" />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SkeletonCard;

