import axios from "axios";
import { Campaign, Partner, PointOfSale, Site } from "../model/models";

let API_URL : string = '';
if (process.env.REACT_APP_API_URL) {
    API_URL = process.env.REACT_APP_API_URL;
}   else {
    API_URL = 'http://localhost:3576/games/publicsite';
}

export const getPointOfSale = () :  Promise<boolean|PointOfSale> => {
    return axios.get(API_URL+'/pointofsale')
        .then(async (res : any) => {
            let pointOfSaleApi = res.data.results;
            if (pointOfSaleApi) {
                let pointOfSale : PointOfSale = {
                    pointOfSaleId: pointOfSaleApi.point_of_sale_id,
                    name: pointOfSaleApi.name,
                    address: pointOfSaleApi.address,
                    sites : []
                }
                pointOfSaleApi.sites.forEach((siteApi:{
                    site_id: string,
                    site_name: string,
                    site_title: string,
                    site_description: string,
                    site_title_secondary: any;
                    site_description_secondary: any;
                    site_logo: string,
                    site_color_main: string,
                    site_title_button_primary: string,
                    site_email_button_primary: string,
                    site_title_button_secondary: string,
                    site_email_button_secondary: string,
                    site_regulation: string,
                    site_pdc: string,
                    site_ga: string,
                    deleted: string,
                    deleted_date: Date,
                }) => {
                    let site : Site = {
                        siteId: parseInt(siteApi.site_id),
                        siteName: siteApi.site_name,
                        siteTitle: siteApi.site_title,
                        siteDescription: siteApi.site_description,
                        siteTitleSecondary: siteApi.site_title_secondary,
                        siteDescriptionSecondary: siteApi.site_description_secondary,
                        siteLogo: siteApi.site_logo,
                        siteColorMain: siteApi.site_color_main,
                        siteTitleButtonPrimary: siteApi.site_title_button_primary,
                        siteEmailButtonPrimary: siteApi.site_email_button_primary,
                        siteTitleButtonSecondary: siteApi.site_title_button_secondary,
                        siteEmailButtonSecondary: siteApi.site_email_button_secondary,
                        siteRegulation: siteApi.site_regulation,
                        sitePdc: siteApi.site_pdc,
                        siteGa: siteApi.site_ga,
                        deleted: parseInt(siteApi.deleted),
                        deletedDate: siteApi.deleted_date,
                        menus: {
                            contact : [],
                            link : [],
                            dropdown : []
                        },
                        partners :  []
                    }
                    pointOfSale.sites.push(site);
                });
                let site = pointOfSale.sites[0];
                await getSiteImages(site);
                await getSiteMenu(site);
                await getSitePartners(site);

                return pointOfSale;
            }   else {
                return false
            }
        })
        .catch((error)=>{
            return false;
        });
}

const getSiteImages = (site: Site) : Promise<void> => {
    return axios.get(API_URL+'/siteimages/'+site.siteId)
        .then((res : any) => {
            let siteImagesApi = res.data.results;
            if (siteImagesApi) {
                site.siteLogo = siteImagesApi.site.site_logo;
            }
        })
        .catch((error)=>{
        });
};

// POS CAMPAIGNS
export const getActiveCampaigns = (pointOfSaleId: number) => {
    return axios.get(API_URL+'/campaign/'+pointOfSaleId)
        .then((res : any) => res.data.results.sort((a: Campaign, b: Campaign) => b.campaignId - a.campaignId))
        .catch((error)=>{
            return error
        });
};


// PARTNERS
const getSitePartners = (site: Site) : Promise<void> => {
    return axios.get(API_URL+'/sitepartners/'+site.siteId)
        .then(async (res : any) => {
            let partnersApi = res.data.results;
            if (partnersApi.length > 0) {
                partnersApi.forEach((partner:{
                    section: string;
                    partner_id: string,
                    partner_name: string,
                    partner_description: string,
                    partner_image: string,
                    partner_logo: string,
                }) => {
                    site.partners.push({
                        partnerId: parseInt(partner.partner_id),
                        partnerName: partner.partner_name,
                        partnerDescription: partner.partner_description,
                        partnerLogo: partner.partner_logo,
                        partnerImage: partner.partner_image,
                        section: partner.section,
                        campaigns: []
                    });
                });
                await getPartnerImages(site);
                await getPartnerCampaigns(site);
            }
        })
        .catch((error)=>{
        });
};

const getPartnerImages = (site: Site) : Promise<void> => {
    return axios.get(API_URL+'/partnerimages/'+site.siteId)
        .then((res : any) => {
            let partnerImagesApi = res.data.results.partners;
            if (partnerImagesApi) {
                partnerImagesApi.forEach((partner : {
                    partner_id: string,
                    partner_image: string,
                    partner_logo: string
                }) => {
                    var site_partner = site.partners.find(the_partner => the_partner.partnerId === parseInt(partner.partner_id));
                    if (site_partner) {
                        site_partner.partnerLogo = partner.partner_logo;
                        site_partner.partnerImage = partner.partner_image;    
                    }
                })
            }
        })
        .catch((error)=>{
        });
};

const getPartnerCampaigns = (site: Site) : Promise<void> => {
    let partner_ids : Array<any> = [];
    site.partners.forEach(function(partner) {
        partner_ids.push(partner.partnerId);
    });
    return axios.get(API_URL+'/sitepartnerscampaign/'+partner_ids)
        .then((res : any) => {
            // Return array in results order by partner_id and composed of null value or Array of campaigns for the partner_id
            let partnerCampaignsApi : Array<any> = res.data.results;
            if (partnerCampaignsApi) {
                site?.partners.forEach((partner : Partner) => {
                    var campaigns = partnerCampaignsApi[partner.partnerId];
                    if (campaigns) {
                        campaigns.forEach((the_campaign : any) => {
                            let campaign : Campaign = {
                                campaignId : the_campaign.campaign_id,
                                campaignName : the_campaign.campaign_name,
                                campaignDescription : the_campaign.campaign_description,
                                campaignShortUrl : the_campaign.campaign_short_url,
                                campaignImage : the_campaign.campaign_partner_image
                            }
                            partner.campaigns?.push(campaign);  
                        });    
                    }
                });
            }
        })
        .catch((error)=>{
        });
};

const getSiteMenu = (site: Site) : Promise<void> => {
    return axios.get(API_URL+'/sitemenu/'+site.siteId)
        .then((res : any) => {
            let menuApi = res.data.results;
            if (menuApi.length > 0) {
                menuApi.forEach((menu:{
                    site_menu_link_id: number,
                    site_menu_link_title: string,
                    site_menu_link_value: string,
                    site_menu_link_type: string,
                    site_menu_link_order: number
                }) => {
                    switch (menu.site_menu_link_type) {
                        case 'contact':
                        case 'link':
                        case 'dropdown':
                            site.menus[menu.site_menu_link_type].push({
                                siteMenuLinkId: menu.site_menu_link_id,
                                siteMenuLinkTitle: menu.site_menu_link_title,
                                siteMenuLinkValue: menu.site_menu_link_value,
                                siteMenuLinkType: menu.site_menu_link_type,
                                siteMenuLinkOrder: menu.site_menu_link_order
                            });
                            break;
                    }
                });
            }
        })
        .catch((error)=>{
        });
}