import React, { useEffect } from "react";

import { useContext } from "react";
import { IntlProvider } from "react-intl";

import fr_FR from "../translations/fr_FR";
import en_US from "../translations/en_US";

import moment from "moment";

import { AppContext } from "../state/AppContextProvider";

const messages: Record<string, Record<string, string>> = {
  fr_FR: fr_FR,
  en_US: en_US,
};

type LangProps = {
  children: React.ReactNode; // 👈️ type children
};

const Lang = (props: LangProps) => {
  const { state } = useContext(AppContext);

  useEffect(() => {
    moment.locale(state.locale.split("_")[0]);
  }, [state.locale]);

  return (
    <IntlProvider
      locale={state.locale.replace("_", "-")}
      messages={messages[state.locale]}
    >
      {props.children}
    </IntlProvider>
  );
};

export default Lang;