import SkeletonCard from "./SkeletonCard";

const SkeletonCards  : React.FC<{}> = () => {
  return (
    <div className="flex flex-wrap">
     <SkeletonCard/>
     <SkeletonCard/>
     <SkeletonCard/>
    </div>
  );
};
export default SkeletonCards;