import { useContext } from "react";
import { AppContext } from "../state/AppContextProvider";
import { ErrorBoundary } from 'react-error-boundary';

interface ErrorProps {}

const Error: React.FC<ErrorProps> = (props: any) => {
    const { state } = useContext(AppContext);

    function ErrorFallback() {
        return (
            <h1>Une erreur est apparue.</h1>
        )
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {state.setErrorMode === false ? (
                <>{props.children}</>
            ) : (
                <div className="h-screen flex items-center justify-center bg-[#F2F2F2]">
                    <div className="bg-white w-[416px] flex flex-col items-center justify-center gap-7 px-14 py-24 text-center rounded-2xl ">
                        <svg width="72" height="65" viewBox="0 0 72 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M70.5859 49.4132L44.8262 5.09496C40.879 -1.69832 31.1232 -1.69832 27.1741 5.09496L1.41439 49.4132C-2.5721 56.2742 2.34414 64.9014 10.2423 64.9014H61.7599C69.6561 64.9014 74.5742 56.2742 70.5859 49.4132ZM35.9964 55.7489C33.7534 55.7489 31.9369 53.9207 31.9369 51.6632C31.9369 49.4057 33.7534 47.5774 35.9964 47.5774C38.2394 47.5774 40.0558 49.4075 40.0558 51.6632C40.0558 53.9188 38.2394 55.7489 35.9964 55.7489ZM39.1205 40.7032C39.0288 43.2751 38.23 44.7889 35.9964 44.7889C33.7628 44.7889 32.964 43.0831 32.8723 40.7032L31.9369 22.724C31.9369 20.4759 33.7628 18.6382 35.9964 18.6382C37.1132 18.6382 38.129 19.0976 38.8642 19.8376C39.5994 20.5775 40.0558 21.5999 40.0558 22.724L39.1205 40.7032Z" fill="#FF494E"/>
                        </svg>
                        <h4 className="m-0">Erreur inattendue...</h4>
                        <h5>Nos services rencontrent actuellement un problème. Merci de réessayer plus tard.</h5>
                    </div>
                </div>
            )}
        </ErrorBoundary>
    );
};

export default Error;