import { useContext } from 'react';
import { AppContext } from '../../state/AppContextProvider';
import Card from '../global/Card';

interface PartnerCardsProps {}

const PartnerCards: React.FC<PartnerCardsProps> = () => {
    const { state } = useContext(AppContext);
    
    return (
        <div className="flex flex-wrap">
            {state.pointOfSale?.sites[0]?.partners?.filter((partner) => partner.section === 'secondary').map((partner, index) =>
                cardWithPropsPartner(index,partner,state.pointOfSale?.sites[0]?.siteColorMain)
            )}
        </div>
    );
};

export const cardWithPropsPartner =  (key:any,partner :any,color?:string) => {
    const {campaigns,partnerId,partnerName, partnerDescription,partnerImage,partnerLogo } = partner;
    // 0 campaign > no url | 1 campaign >  set url | +1 campaigns > no url to let click more button
    const campaignUrl = campaigns.length === 1 && campaigns[0].campaignShortUrl;
    return (
        <Card   
            key={key}
            isCampaign={false}
            id={partnerId}
            mainColor={color}
            image={partnerImage}
            title={partnerName}
            description={partnerDescription}
            partnerLogo={partnerLogo}
            campaignUrl={campaignUrl}
            numberOfCampaigns={campaigns?.length}
        />
    )
};

export default PartnerCards;